import React from 'react'

// conditionally include whyDidYouRender before _ANY_ code that potentially uses React is run per
// https://github.com/welldone-software/why-did-you-render/issues/170
async function run() {
  if (import.meta.env.DEV) {
    // whyDidYouRender loaded in this manner doesn't seem to work in Safari
    const whyDidYouRender = await import(
      '@welldone-software/why-did-you-render'
    )
    console.info('whyDidYouRender enabled')
    whyDidYouRender.default(React)
  }
  import('./main')
}

run()
